import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AlertService } from '@common/services/alert';
import { downloadCanvas } from '@common/utils/file';
import { Button } from '@common/components/button';
import { Modal } from '@common/components/modal';
import { QRCode } from '@common/components/qr-code';
import Confirm from '@common/components/confirm-button';
import { getAccessCode, regenerateAccessCode } from '../../actions';
import type { Network } from '@common/types/objects';
import Icon from '@common/components/icon';

import './access-link.scss';

type Props = {
  network?: Network,
};

const AccessLinkComponent = ({ network }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasCopied, setHasCopied] = React.useState(false);
  const [link, setLink] = React.useState('');
  const QRCodeContainerRef = useRef<HTMLDivElement | null>(null);

  const getLink = (code: string) => `${window.origin}/signup?id=${code}`;

  React.useEffect(() => {
    if (!link) {
      (async () => {
        const code = await dispatch(getAccessCode(network?.id)) as unknown as string;

        setLink(getLink(code));
      })();
    }
  }, [link]);

  const handleCopy = async () => {
    try {
      setHasCopied(true);

      await navigator.clipboard.writeText(link);
    } catch (err) {
      AlertService.warning(t('organisation:no_access_link'));
    }
  };

  const handleRegenerate = async () => {
    try {
      const code = await dispatch(regenerateAccessCode()) as unknown as string;

      setLink(getLink(code));
      AlertService.success(t('organisation:regenerated_access_link'));
    } catch (err) {
      AlertService.warning(t('organisation:regenerate_access_link_warning'));
    }
  };

  const handleDownloadQR = () => {
    const canvas = QRCodeContainerRef.current?.children[0] as HTMLCanvasElement;

    if (!canvas) return;

    downloadCanvas(canvas, 'QR Code.png');
  };

  return (
    <Modal
      list
      hideDefaultClose
      size="small"
      className="AccessLinkModal"
      title={t('organisation:invite_link')}
      content={(
        <>
          <b>{t('organisation:invite_link_description')}</b>
          <div className="Form__control AccessLinkModalInput">
            <Icon type="insert_link" className="AccessLinkModalInput__Icon" />
            <input type="text" placeholder={t('organisation:invite_link')} value={link} readOnly />
            <span className="AccessLinkModalInput__Button" onClick={handleCopy} role="button">
              {t(hasCopied ? 'organisation:copied_invite_link' : 'organisation:copy_link')}
            </span>
          </div>
          <div className="AccessLinkModalActions">
            <Button
              size="small"
              type="primary"
              disabled={!link}
              onClick={() => (link && window?.open(link, '_blank')?.focus())}
            >
              {t('organisation:preview_link')}
            </Button>
            <Confirm
              title={t('organisation:regenerate_link_warning')}
              onConfirm={handleRegenerate}
            >
              <Button size="small" type="inverted-primary" icon="refresh">
                {t('organisation:regenerate_link')}
              </Button>
            </Confirm>
          </div>
          <p>
            <b>{t('organisation:invite_link_print_header')}</b>
            <br />
            {t('organisation:invite_link_print_description')}
          </p>
        </>
      )}
      footer={(
        <>
          <div className="AccessLinkModal__Explanation">
            <b>{t('organisation:invite_link_info_header')}</b>
            <ul>
              <li>{t('core:signup_form_label_first_name')}</li>
              <li>{t('core:signup_form_label_last_name')}</li>
              <li>{t('core:signup_form_label_email')}</li>
              <li>{t('core:signup_form_label_network')}</li>
              <li>{t('core:signup_form_label_function')}</li>
            </ul>
          </div>
          <div ref={QRCodeContainerRef} className="AccessLinkModal__QRCode">
            {link && <QRCode value={link} size={640} />}
            <div>
              <Trans
                i18nKey="organisation:invite_link_scan_code"
                components={[
                  <a onClick={handleDownloadQR} role="button" />,
                ]}
              />
            </div>
          </div>
        </>
      )}
    >
      <Button size="large" icon="qr_code">
        {t('organisation:get_invite_link')}
      </Button>
    </Modal>
  );
};

export const AccessLink = AccessLinkComponent;
