import Api from '../../../common/services/api';

export const NETWORK_RESEND_INVITATION = 'network/RESEND_INVITATION';

export default (user) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  await Api.post(`/v2/networks/${selected}/users/${user.id}/reinvite`);

  dispatch({
    type: NETWORK_RESEND_INVITATION,
    userId: user.id,
  });
};
