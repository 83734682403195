import React, { useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { EUserStatus } from '@common/utils/user';
import { useAppSelector, usePermission } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import { TableDropdown } from '@common/components/table';
import Permission from '@common/components/permission';
import ConfirmButton from '@common/components/confirm-button';
import { EPermissions } from '@common/definitions';
import Api from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { useDispatch } from 'react-redux';
import { restoreUser } from '@modules/admin/actions';
import './styles.scss';


const UserActions = ({
  item, loggedUser, onResendInvitation, onView, onEdit, onDelete, t, userStatus
}) => {
  const [isSending, setSending] = useState(false);
  const dispatch = useDispatch();

  const handleResendInvitation = async () => {
    setSending(true);

    await onResendInvitation(item);

    setSending(false);
  };

  const org = useAppSelector(selected);

  const orgHasIntegration = !!org?.integration;

  const canUpdate = usePermission(EPermissions.ORGANISATION_USERS_UPDATE);

  if (userStatus === EUserStatus.DELETED) {
    if (!canUpdate) return null;
    const onRestoreConfirmClick = async () => {
      try {
        await Api.post(`/v2/organisations/${org.id}/users/${item.id}/restore`);
        dispatch(restoreUser(item.id));
      } catch (error) {
        AlertService.error(t('common:something_went_wrong'));
        throw error;
      }
    };
    return (
      <TableDropdown id={`user-action-${item.id}`}>
        <ConfirmButton
          danger
          title={t('common:overview_content_confirm')}
          className="UserActions_Confirm"
          description={
            <>
              { t('organisation:user_restore_confirm_message') }
              {
                orgHasIntegration && (
                  <span>
                    { t('organisation:user_restore_confirm_integration') }
                  </span>
                )
              }
            </>
          }
          onConfirm={onRestoreConfirmClick}
        >
          <TableDropdown.Item>
            <Trans i18nKey="organisation:restore" />
          </TableDropdown.Item>
        </ConfirmButton>
      </TableDropdown>
    );
  }

  return (
    <TableDropdown id={`user-action-${item.id}`} disabled={isSending}>
      <Permission name={EPermissions.ORGANISATION_USERS_UPDATE}>
        <TableDropdown.Item onClick={() => onEdit(item)}>
          <Trans i18nKey="organisation:user_actions_edit" />
        </TableDropdown.Item>
      </Permission>
      <TableDropdown.Item onClick={() => onView(item)}>
        <Trans i18nKey="organisation:user_actions_view" />
      </TableDropdown.Item>
      <Permission name={EPermissions.ORGANISATION_USERS_CREATE}>
        {!item.invited_at && !item.last_login && (
          <TableDropdown.Item onClick={handleResendInvitation}>
            <Trans i18nKey="organisation:user_actions_send_invitation" />
          </TableDropdown.Item>
        )}
        {item.invited_at && !item.last_login && (
          <TableDropdown.Item
            onClick={!isSending ? handleResendInvitation : undefined}
            disabled={isSending}
          >
            <Trans i18nKey="organisation:user_actions_resend_invitation" />
          </TableDropdown.Item>
        )}
      </Permission>
      {!item.external_id && item.id !== loggedUser.id && (
        <Permission name={EPermissions.ORGANISATION_USERS_REMOVE}>
          <ConfirmButton
            danger
            title={t('organisation:user_actions_delete_confirm_title')}
            description={t('organisation:user_actions_delete_confirm_description')}
            onConfirm={() => onDelete(item.id)}
            confirmText={t('organisation:user_actions_delete_confirm_text')}
          >
            <TableDropdown.Item danger>
              <Trans i18nKey="organisation:user_actions_delete" />
            </TableDropdown.Item>
          </ConfirmButton>
        </Permission>
      )}
    </TableDropdown>
  );
};

export default withTranslation()(UserActions);
