import * as R from 'ramda';
import Api from '../../../common/services/api';

export const NETWORK_INVITE_USER = 'network/INVITE_USER';

const whitelist = [
  'first_name',
  'last_name',
  'email',
  'phone_num',
  'dial_code',
  'date_of_birth',
  'team_ids',
  'role_ids',
  'external_id',
  'function_ids',
  'onboarding',
  'onboarding_due_date',
  'active_until',
  'language_locale',
];

export default (values) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const payload = R.pick(whitelist, values);
  payload.email = payload.email.trim();

  const { data: user } = await Api.post(`/v2/networks/${selected}/users`, payload);

  // do not rely on role.memberships[], see comments on PD-8672
  const { roles } = R.find(R.propEq('id', selected), user.scopes.networks);

  dispatch({
    type: NETWORK_INVITE_USER,
    user,
    roles,
  });
};
