import * as React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import * as R from 'ramda';
import Tabs from '@common/components/tabs';
import * as AlertService from '@common/services/alert';
import { Button } from '@common/components/button';
import Modal from '@common/components/modal';
import Permission from '@common/components/permission';
import {
  Row, Group, Label,
  TextInput, SelectInput, CheckboxInput, DatePickerInput,
  LanguageInput, PhoneNumberInput,
} from '@common/components/form';
import { EComponentTypes } from '@common/definitions';
import { inviteUser } from '../../actions';
import validate from '../validators/invitation';

require('./styles.scss');

const transformToOption = (object) => ({ label: object.name, value: object.id });

class OrganisationInvitationForm extends React.Component {
  constructor(props) {
    super(props);

    this.focusInput = this.focusInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setReference = (ref) => (this.modal = ref);
  }

  static props;
  modal;

  focusInput() {
    const emailInput = document.querySelector('[name="email"]');

    if (emailInput) emailInput.focus();
  }

  async handleSubmit(values, dispatch, props, close = true) {
    const { reset, t } = this.props;

    try {
      const payload = R.omit(['networks', 'functions', 'onboarding_due_date', 'roles', 'phone'], values);

      const getValues = R.pluck('value');
      payload.network_ids = getValues(values.networks);
      payload.function_ids = getValues(values.functions);
      payload.role_ids = getValues(values.roles);

      if (values.phone?.number) {
        payload.dial_code = values.phone.dial_code;
        payload.phone_num = values.phone.number;
      } else {
        payload.dial_code = null;
        payload.phone_num = null;
      }

      if (payload.onboarding && values.onboarding_due_date) {
        payload.onboarding_due_date = values.onboarding_due_date.format('YYYY-MM-DD');
      }

      if (values.date_of_birth) payload.date_of_birth = values.date_of_birth.format('YYYY-MM-DD');
      if (values.active_until) payload.active_until = values.active_until.format('YYYY-MM-DD');

      await dispatch(inviteUser(payload));

      AlertService.success(
        <span>
          <Trans
            i18nKey="organisation:forms_invitation_success"
            values={{ firstName: payload.first_name, lastName: payload.last_name }}
            components={[<b>a</b>]}
          />
        </span>,
      );

      reset();

      if (close && this.modal) this.modal.handleClose();
      if (!close) this.focusInput();
    } catch (response) {
      if (response.type === 'duplicate_user_error') {
        return AlertService.warning(t('organisation:forms_invitation_warning'));
      }

      return AlertService.forStatus(response.status_code, {
        warning: t('organisation:forms_invitation_validation_warning'),
        error: t('organisation:forms_invitation_error'),
      });
    }
  }

  render() {
    const {
      networks, functions, roles, submitting, formValues = {}, invalid, handleSubmit, t,
    } = this.props;

    return (
      <Modal
        list
        size="small"
        title={t('organisation:forms_invitation_title')}
        ref={this.setReference}
        onEntered={this.focusInput}
        wrapper={Modal.FormWrapper}
        content={(
          <Tabs>
            <Tabs.Item title={t('organisation:forms_invitation_general')}>
              <Row>
                <Group>
                  <Label for="email" text={t('organisation:forms_invitation_email')} required />
                  <TextInput name="email" />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label for="first_name" text={t('organisation:forms_invitation_first_name')} required />
                  <TextInput name="first_name" />
                </Group>
                <Group>
                  <Label for="last_name" text={t('organisation:forms_invitation_last_name')} required />
                  <TextInput name="last_name" />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label required for="networks" text={t('organisation:forms_invitation_communities')} />
                  <SelectInput
                    searchable
                    multi
                    name="networks"
                    options={networks.map(transformToOption)}
                  />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label for="functions" text={t('organisation:forms_invitation_functions')} />
                  <SelectInput
                    searchable
                    multi
                    name="functions"
                    options={functions.map(transformToOption)}
                  />
                </Group>
              </Row>
            </Tabs.Item>
            <Tabs.Item title={t('organisation:forms_invitation_optional')}>
              <Row>
                <Group>
                  <Label for="external_id" text={t('organisation:forms_invitation_unique_identifier')} />
                  <TextInput name="external_id" />
                </Group>
                <Group>
                  <Label
                    text={t('organisation:forms_invitation_date_out_of_service')}
                    description={t('organisation:forms_invitation_date_out_of_service_description')}
                  />
                  <DatePickerInput
                    name="active_until"
                    minDate={moment()}
                  />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label text={t('organisation:forms_invitation_phone_number')} />
                  <PhoneNumberInput name="phone" />
                </Group>
                <Group>
                  <Label text={t('organisation:forms_invitation_date_of_birth')} />
                  <DatePickerInput
                    name="date_of_birth"
                  />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label text={t('organisation:forms_invitation_language')} />
                  <LanguageInput name="language_locale" />
                </Group>
              </Row>
              <Permission component={EComponentTypes.ONBOARDING}>
                <Row>
                  <Group>
                    <CheckboxInput
                      name="onboarding"
                      label={t('organisation:forms_invitation_onboarding')}
                    />
                  </Group>
                </Row>
                {formValues.onboarding && (
                  <Row>
                    <Group>
                      <Label text={t('organisation:forms_invitation_onboarding_due_date')} />
                      <DatePickerInput
                        name="onboarding_due_date"
                        minDate={moment()}
                      />
                    </Group>
                  </Row>
                )}
              </Permission>
            </Tabs.Item>
            <Tabs.Item title={t('organisation:forms_invitation_permissions')}>
              <Row>
                <Group>
                  <Label for="roles" text={t('organisation:forms_invitation_roles')} />
                  <SelectInput
                    searchable
                    multi
                    name="roles"
                    placeholder={t('organisation:forms_invitation_roles_basic_user')}
                    options={roles.organisation.map(transformToOption)}
                  />
                </Group>
              </Row>
            </Tabs.Item>
          </Tabs>
        )}
        footer={(
          <>
            <Button
              type="primary"
              disabled={invalid}
              isLoading={submitting}
              // $FlowFixMe
              onClick={handleSubmit((...props) => this.handleSubmit(...props, false))}
            >
              <Trans i18nKey="organisation:forms_invitation_submit_and_new" />
            </Button>
            <Button
              type="primary"
              disabled={invalid}
              isLoading={submitting}
              onClick={handleSubmit(this.handleSubmit)}
            >
              <Trans i18nKey="organisation:forms_invitation_submit" />
            </Button>
          </>
        )}
      >
        {this.props.children}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const organisation = state.organisation.selected;
  const dueDateOffset = (organisation && organisation.settings)
    ? organisation.settings.onboarding_due_date_offset
    : 30;

  return {
    formValues: (state.form['organisation-invitation'] || { values: {} }).values,
    initialValues: { // Defined here because we need to calculate the default due date offset
      email: '',
      first_name: '',
      last_name: '',
      phone: {
        dial_code: '+31',
        number: '',
      },
      functions: [],
      networks: [],
      roles: [],
      onboarding: true,
      onboarding_due_date: moment().add(dueDateOffset, 'days'),
      language_locale: organisation.language.locale,
    },
  };
};

export default withTranslation()(connect(mapStateToProps)(reduxForm({
  form: 'organisation-invitation',
  destroyOnUnmount: false,
  validate,
})(OrganisationInvitationForm)));
