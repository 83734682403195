import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router';
import * as R from 'ramda';
import * as qs from 'qs';
import { SubNavigationBar, TopNavigationBar } from '@common/components/navigation-bar';
import ProfileImage from '@common/components/profile-image';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as AlertService from '../../../../common/services/alert';
import { EUserStatus } from '../../../../common/utils/user';
import Overview from '../../../../common/components/overview';
import { Button } from '../../../../common/components/button';
import Permission from '../../../../common/components/permission';
import Confirm from '../../../../common/components/confirm-button';
import Table from '../../../../common/components/table';
import Container from '../../../../common/components/container';
import SearchBar from '../../../../common/components/search-bar';
import { AccessLink } from '../../../admin/components/access-link';
import { AccessRequestList } from '../../../admin/components/access-request-list';
import UserActions from '../../components/user-actions';
import InvitationForm from '../../forms/invitation';
import * as networkSelector from '../../selectors/network';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as userSelector from '../../../core/selectors/logged-user';
import { EPermissions } from '../../../../common/definitions';

require('./styles.scss');

class EmployeesContainer extends React.Component {
  static defaultProps = {
    form: { values: {} },
  };

  constructor(props) {
    super();

    this.handleViewUser = this.handleViewUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleResendInvitation = this.handleResendInvitation.bind(this);
    this.handleReinviteUsers = this.handleReinviteUsers.bind(this);
    this.handleOnChangeSearch = (query) => {
      const { match: { url } } = this.props;

      return query ? props.history.push(`${url}?q=${query}`) : props.history.push(url);
    };
  }

  componentDidMount() {
    const { roles, fetchRoles, fetchUserCounts } = this.props;

    fetchUserCounts();
    if (roles.network.length === 0) fetchRoles();
  }

  componentWillUnmount() {
    // Reset filters and clear invitation form
    this.props.destroyForm('network-invitation');
  }

  static props;


  handleViewUser(user) {
    const { loggedUser, history, match: { params } } = this.props;

    history.push(user.id === loggedUser.id ? '/profile/about' : `/networks/${params.networkId}/users/${user.id}/about`);
  }

  handleEditUser(user) {
    const { loggedUser, history, match: { params } } = this.props;

    history.push(user.id === loggedUser.id ? '/profile/about/edit' : `/networks/${params.networkId}/users/${user.id}/about/edit`);
  }

  async handleResendInvitation(user) {
    try {
      await this.props.resendInvitation(user);
      AlertService.success(<Trans i18nKey="network:user_reinvite_success" values={{ email: user.email }} components={[<b>a</b>]} />);
    } catch (response) {
      AlertService.forStatus(response, {
        warning: this.props.t('network:user_reinvite_warning'),
        error: this.props.t('network:user_reinvite_error'),
      });
    }
  }

  async handleReinviteUsers() {
    const { history, match: { params }, reinviteUsers } = this.props;

    try {
      await reinviteUsers();

      history.push(`/networks/${params.networkId}/users`);
      AlertService.success(<Trans i18nKey="organisation:resend_invitations_success" />);
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: this.props.t('organisation:resend_invitations_warning'),
        error: this.props.t('organisation:resend_invitations_error'),
      });
    }
  }

  render() {
    const {
      match: { params },
      location,
      loggedUser,
      organisation,
      network,
      users,
      teams,
      functions,
      roles,
      counts,
      permissions,
      t,
    } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    const canSeeEmail = permissions.includes(EPermissions.NETWORK_USERS_VIEW);
    const filterEmptyColumn = R.reject(R.isNil);

    const columns = filterEmptyColumn([
      { className: 'Table__Cell--size-auto' },
      { label: this.props.t('network:user_name'), className: 'Table__Cell__Title fs-mask' },
      canSeeEmail ? { label: this.props.t('network:user_email'), className: 'hidden-sd hidden-md fs-mask' } : undefined,
      { label: this.props.t('network:user_phone_number'), className: 'fs-mask' },
    ]);

    const renderRow = ({ item: user }) => filterEmptyColumn([
      <ProfileImage size={35} user={user} />,
      <a onClick={() => this.handleViewUser(user)}>{user.full_name}</a>,
      canSeeEmail ? <span>{user.email}</span> : undefined,
      user.phone_num ? <span>{user.phone_num}</span> : <small><Trans i18nKey="network:user_phone_empty" /></small>,
    ]);

    const statusLookup = {
      all: {
        filter: false,
        count: counts.total,
      },
      active: {
        filter: EUserStatus.ACTIVE,
        count: counts.active,
        tooltip: t('organisation:users_tooltip_active'),
      },
      inactive: {
        filter: EUserStatus.INACTIVE,
        count: counts.inactive,
        tooltip: t('organisation:users_tooltip_inactive'),
      },
      not_logged_in: {
        filter: EUserStatus.NOT_LOGGED_IN,
        count: counts.not_logged_in,
        tooltip: t('organisation:users_tooltip_not_logged_in'),
      },
      not_invited: {
        filter: EUserStatus.NOT_INVITED,
        count: counts.not_invited,
      },
      requests: {
        filter: 'requests',
        count: counts.access_requests,
      },
    };

    // Map url to status
    const currentStatus = statusLookup[params.filter] || statusLookup.all;

    const getUrlWithSearch = (url) => (query.q ? `${url}?q=${query.q}` : url);

    return (
      <>
        <SubNavigationBar title={t('core:tab_coworkers')}>
          <SubNavigationBar.Item
            exact
            path={getUrlWithSearch(`/networks/${params.networkId}/users`)}
            title={t('organisation:users_filter_all')}
            count={counts.total}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/active`)}
            title={t('organisation:users_filter_active')}
            count={counts.active}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/inactive`)}
            title={t('organisation:users_filter_inactive')}
            count={counts.inactive}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/not_logged_in`)}
            title={t('organisation:users_filter_not_logged_in')}
            count={counts.not_logged_in}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/not_invited`)}
            title={t('organisation:users_filter_not_invited')}
            count={counts.not_invited}
          />
          {organisation.access_request_links_enabled && (
            <Permission name={EPermissions.NETWORK_USERS_VIEW}>
              <SubNavigationBar.Item
                path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/requests`)}
                title={t('organisation:users_filter_requests')}
                count={counts.access_requests}
              />
            </Permission>
          )}
        </SubNavigationBar>
        <Container name="NetworkUsers">
          <TopNavigationBar
            title={t('organisation:users_filter', { context: params.filter || 'all' })}
            tooltip={currentStatus.tooltip}
            count={currentStatus.count}
            searchbar={(
              <SearchBar
                key={params.filter}
                placeholder={t('organisation:users_filter_search_placeholder')}
                onSearch={this.handleOnChangeSearch}
                defaultValue={query.q}
              />
            )}
            action={(
              <>
                {organisation.access_request_links_enabled && (
                  <Permission name={EPermissions.NETWORK_USERS_CREATE}>
                    <AccessLink organisation={organisation} network={network} />
                  </Permission>
                )}
                {currentStatus.filter === EUserStatus.NOT_INVITED && currentStatus.count > 0 && (
                  <Confirm title={t('organisation:users_confirm_send_invitation')} onConfirm={this.handleReinviteUsers}>
                    <Button type="orange"><Trans i18nKey="organisation:users_invite_pending" values={{ count: currentStatus.count }} /></Button>
                  </Confirm>
                )}
                <Permission name={EPermissions.NETWORK_USERS_CREATE}>
                  <InvitationForm teams={teams} functions={functions} roles={roles}>
                    <Button type="primary" iconRight="add" size="large">
                      <div style={{ display: 'inline' }}>
                        <span className="hidden-sd hidden-md"><Trans i18nKey="network:user_invite" /></span>
                        <span className="visible-sd visible-md"><Trans i18nKey="network:user_invite_short" /></span>
                      </div>
                    </Button>
                  </InvitationForm>
                </Permission>
              </>
            )}
          />
          <Container.Content>
            <Overview>
              <Overview.Content>
                <Switch>
                  {organisation.access_request_links_enabled && (
                    <Route path="/networks/:networkId/users/filter/requests">
                      <AccessRequestList network={network} query={query.q} />
                    </Route>
                  )}
                  <Route>
                    <Table
                      columns={columns}
                      items={users}
                      data={{
                        onFetch: this.props.fetchUsers,
                        filter: {
                          status: currentStatus.filter || undefined,
                          query: query.q,
                        },
                      }}
                      renderRow={renderRow}
                      ActionComponent={(({ item }) => (
                        <Permission name={EPermissions.NETWORK_USERS_CREATE}>
                          <UserActions
                            item={item}
                            loggedUser={loggedUser}
                            onView={this.handleViewUser}
                            onEdit={this.handleEditUser}
                            onResendInvitation={this.handleResendInvitation}
                            onDelete={this.props.deleteUser}
                          />
                        </Permission>
                      ))}
                      placeholder={(
                        <div className="Users__Placeholder">
                          <div>
                            <Trans i18nKey="network:user_filter_empty_placeholder" />
                            <br />
                            <img src="/static/images/people.svg" alt="PeopleVector" />
                          </div>
                        </div>
                      )}
                    />
                  </Route>
                </Switch>
              </Overview.Content>
            </Overview>
          </Container.Content>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUser: userSelector.selected(state),
  organisation: organisationSelector.selected(state),
  network: networkSelector.selected(state),
  permissions: userSelector.permissions(state),
  users: networkSelector.users(state),
  teams: networkSelector.teams(state),
  functions: networkSelector.functions(state),
  roles: organisationSelector.roles(state),
  form: state.form.invitation,
  ui: state.ui.network.employees,
  counts: state.network.users.counts,
});

const mapDispatchToProps = {
  fetchRoles: require('../../../organisation/actions').fetchRoles,
  resendInvitation: require('../../actions/resend-invitation').default,
  deleteUser: require('../../actions/delete-user').default,
  fetchUsers: require('../../actions/fetch-users').default,
  reinviteUsers: require('../../actions').reinviteUsers,
  fetchUserCounts: require('../../actions').fetchUserCounts,
  destroyForm: require('redux-form').destroy,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_EMPLOYEES_PAGE)(EmployeesContainer)));
