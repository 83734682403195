import memoize from '../../../common/utils/memoize';
import type { StoreState } from '../../../common/types/store';
import type { AccessRequest } from '../types';

const getIds = (state: StoreState) => state.admin.accessRequests;

export const items = memoize.createSelector<AccessRequest[]>(
  [getIds],
  (state, requests) => requests as AccessRequest[],
);
