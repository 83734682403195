import * as validationUtil from '../../../../common/utils/validation';

export default (values) => {
  const errors = {};

  if (!values?.first_name) errors.first_name = true;
  if (!values?.last_name) errors.last_name = true;
  if (!values?.email || !validationUtil.test('EMAIL', values.email)) errors.email = true;

  return errors;
};
