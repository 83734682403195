import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { Switch, Route } from 'react-router';
import * as qs from 'qs';

import ProfileImage from '@common/components/profile-image';
import FileExportDropdown from '@common/components/dropdown/file-export-dropdown';
import { getApiHeaders } from '@common/services/api/utils';
import { downloadFile } from '@common/utils/file';
import { BASE_URL } from '@common/constants';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as AlertService from '../../../../common/services/alert';
import Container from '../../../../common/components/container';
import Overview from '../../../../common/components/overview';
import Confirm from '../../../../common/components/confirm-button';
import Table from '../../../../common/components/table';
import Permission from '../../../../common/components/permission';
import Dropdown from '../../../../common/components/dropdown';
import { Button } from '../../../../common/components/button';
import { SubNavigationBar, TopNavigationBar } from '../../../../common/components/navigation-bar';
import SearchBar from '../../../../common/components/search-bar';
import { EUserStatus } from '../../../../common/utils/user';
import UserActions from '../../components/user-actions';
import { AccessRequestList } from '../../components/access-request-list';
import { AccessLink } from '../../components/access-link';
import InvitationForm from '../../forms/invitation';
import usersSelector from '../../../organisation/selectors/users';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as userSelector from '../../../core/selectors/logged-user';
import { EPermissions } from '../../../../common/definitions';

require('./styles.scss');

const reinviteStatusses = [EUserStatus.NOT_INVITED, EUserStatus.NOT_LOGGED_IN];

class OrganisationUsers extends React.Component {
  constructor(props) {
    super();

    this.handleImportFromFile = () => props.history.push('/admin/users/import');
    this.handleEdit = (user) => props.history.push(
      props.loggedUser.id === user.id ? '/admin/profile/about/edit' : `/admin/users/${user.id}/about/edit`,
    );
    this.handleView = (user) => props.history.push(`/admin/users/${user.id}/about`);
    this.handleOnChangeSearch = (query) => {
      const { match: { url } } = this.props;

      return query ? props.history.push(`${url}?q=${query}`) : props.history.push(url);
    };
    this.handleResendInvitation = this.handleResendInvitation.bind(this);
    this.handleReinviteUsers = this.handleReinviteUsers.bind(this);
    this.downloadUsers = this.downloadUsers.bind(this);
  }

  componentDidMount() {
    const { roles, fetchRoles, fetchCounts } = this.props;

    if (roles.organisation.length === 0) fetchRoles();
    fetchCounts();
  }

  componentWillUnmount() {
    // Reset filters and clear invitation form
    this.props.destroyForm('organisation-invitation');
  }

  static props;

  async handleResendInvitation(user, removeFromList) {
    try {
      await this.props.resendInvitation(user, removeFromList);
      AlertService.success(<Trans i18nKey="network:user_reinvite_success" values={{ email: user.email }} components={[<b>a</b>]} />);
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: this.props.t('organisation:users_invitation_warning'),
        error: this.props.t('organisation:users_invitation_error'),
      });
    }
  }

  async handleReinviteUsers() {
    const { history, match: { params }, reinviteUsers, t } = this.props;

    try {
      AlertService.warning(<Trans i18nKey="organisation:resend_invitations_waiting" />);
      await reinviteUsers(params.filter);

      history.push('/admin/users');
      AlertService.success(<Trans i18nKey="organisation:resend_invitations_success" />);
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: t('organisation:resend_invitations_warning'),
        error: t('organisation:resend_invitations_error'),
      });
    }
  }

  async downloadUsers(format, select) {
    const options = {
      method: 'GET',
      headers: {
        ...(await getApiHeaders()),
        Accept: 'text/csv'
      },
      credentials: 'include',
      cache: 'no-cache'
    };
    const { organisation, t } = this.props;
    const base = `${BASE_URL}/v2/organisations/${organisation.id}/users/export`;

    let url = (`${base}?format=${format}`);
    let section;
    if (select) {
      url += (`&select=${select}`);
      section = select.toLocaleLowerCase();
    } else {
      section = t('organisation:users_filter_all').toLocaleLowerCase();
    }

    const employees = t('core:tab_employees').toLocaleLowerCase();
    const fileName = `${employees}-${section}.${format}`;
    return downloadFile(url, fileName, options);
  }

  render() {
    const {
      loggedUser,
      organisation,
      networks,
      functions,
      users,
      roles,
      counts,
      match,
      t,
    } = this.props;
    const query = qs.parse(window?.location?.search, { ignoreQueryPrefix: true });

    const statusLookup = {
      all: {
        filter: false,
        count: counts.total,
      },
      active: {
        filter: EUserStatus.ACTIVE,
        count: counts.active,
        tooltip: t('organisation:users_tooltip_active'),
      },
      inactive: {
        filter: EUserStatus.INACTIVE,
        count: counts.inactive,
        tooltip: t('organisation:users_tooltip_inactive'),
      },
      not_logged_in: {
        filter: EUserStatus.NOT_LOGGED_IN,
        count: counts.not_logged_in,
        tooltip: t('organisation:users_tooltip_not_logged_in'),
      },
      not_invited: {
        filter: EUserStatus.NOT_INVITED,
        count: counts.not_invited,
        tooltip: t('organisation:users_tooltip_not_invited'),
      },
      requests: {
        filter: 'requests',
        count: counts.access_requests,
      },
      deleted: {
        filter: EUserStatus.DELETED,
        count: counts.deleted
      }
    };

    const { filter } = match.params;
    // Map url to status
    const currentStatus = statusLookup[filter] || statusLookup.all;
    const getUrlWithSearch = (url) => (query.q ? `${url}?q=${query.q}` : url);

    return (
      <>
        <SubNavigationBar title={t('core:tab_employees')}>
          <SubNavigationBar.Item
            exact
            path={getUrlWithSearch('/admin/users')}
            title={t('organisation:users_filter_all')}
            count={counts.total}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch('/admin/users/filter/active')}
            title={t('organisation:users_filter_active')}
            count={counts.active}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch('/admin/users/filter/inactive')}
            title={t('organisation:users_filter_inactive')}
            tooltip={t('organisation:users_tooltip_inactive')}
            count={counts.inactive}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch('/admin/users/filter/not_logged_in')}
            title={t('organisation:users_filter_not_logged_in')}
            count={counts.not_logged_in}
          />
          <SubNavigationBar.Item
            path={getUrlWithSearch('/admin/users/filter/not_invited')}
            title={t('organisation:users_filter_not_invited')}
            count={counts.not_invited}
          />
          {organisation.access_request_links_enabled && (
            <SubNavigationBar.Item
              path={getUrlWithSearch('/admin/users/filter/requests')}
              title={t('organisation:users_filter_requests')}
              count={counts.access_requests}
            />
          )}
          <SubNavigationBar.Item
            path={getUrlWithSearch('/admin/users/filter/deleted')}
            title={t('organisation:users_filter_deleted')}
            tooltip={t('organisation:users_tooltip_deleted')}
            count={counts.deleted}
          />
        </SubNavigationBar>
        <Container name="OrganisationUsers">
          <TopNavigationBar
            title={t('organisation:users_filter', { context: match.params.filter || 'all' })}
            tooltip={currentStatus.tooltip}
            count={currentStatus.count}
            searchbar={(
              <SearchBar
                key={match.params.filter}
                placeholder={t('organisation:users_filter_search_placeholder')}
                onSearch={this.handleOnChangeSearch}
                defaultValue={query.q}
              />
            )}
            action={(
              <>
                {
                  organisation.access_request_links_enabled &&
                  !organisation?.integration?.integration_type && (
                    <Permission name={EPermissions.ORGANISATION_USERS_CREATE}>
                      <AccessLink organisation={organisation} />
                    </Permission>
                  )
                }
                {reinviteStatusses.includes(currentStatus.filter) && currentStatus.count > 0 && (
                  <Confirm
                    title={t('organisation:users_confirm_send_invitation', { count: currentStatus.count })}
                    onConfirm={this.handleReinviteUsers}
                  >
                    <Button type="orange" size="large">
                      <Trans i18nKey="organisation:users_invite_pending" values={{ count: currentStatus.count }} />
                    </Button>
                  </Confirm>
                )}
                <Permission name={EPermissions.ORGANISATION_USERS_CREATE}>
                  <Dropdown
                    id="invite-user"
                    alignRight
                    toggle={(
                      <Button iconRight="add" type="primary" size="large">
                        <span className="hidden-sd hidden-md">
                          <Trans i18nKey="organisation:users_invite" />
                        </span>
                        <span className="visible-sd visible-md">
                          <Trans i18nKey="organisation:users_invite_short" />
                        </span>
                      </Button>
                    )}
                  >
                    <InvitationForm networks={networks} functions={functions} roles={roles}>
                      <Dropdown.Item iconRight="add">
                        <Trans i18nKey="organisation:users_invite_manual" />
                      </Dropdown.Item>
                    </InvitationForm>
                    <Dropdown.Item icon="upload" onClick={this.handleImportFromFile}>
                      <Trans i18nKey="organisation:users_invite_csv" />
                    </Dropdown.Item>
                  </Dropdown>
                </Permission>
                {
                  filter !== 'requests' ?
                    <FileExportDropdown
                      label={t('organisation:download_users')}
                      downloadCSV={() => this.downloadUsers('csv', filter)}
                      downloadXLSX={() => this.downloadUsers('xlsx', filter)}
                    /> :
                    null
                }
              </>
            )}
          />
          <Container.Content>
            <Overview>
              <Overview.Content>
                <Switch>
                  {organisation.access_request_links_enabled && (
                    <Route path="/admin/users/filter/requests">
                      <AccessRequestList query={query.q} />
                    </Route>
                  )}
                  <Route>
                    <Table
                      key="users"
                      columns={[
                        { size: 35 },
                        {
                          label: t('organisation:users_columns_name'),
                          className: 'Table__Cell__Title fs-exclude',
                          size: 'large'
                        },
                        {
                          label: t('organisation:users_columns_email'),
                          size: 'large',
                          className: 'fs-exclude'
                        },
                        {
                          label: t('organisation:users_columns_phone_number'),
                          className: 'hidden-sd hidden-md fs-exclude'
                        }
                      ]}
                      items={users}
                      data={{
                        onFetch: this.props.fetchUsers,
                        filter: {
                          status: currentStatus.filter || undefined,
                          query: query.q,
                        },
                      }}
                      renderRow={({ item: user }) => [
                        <ProfileImage size={35} user={user} />,
                        filter === EUserStatus.DELETED ?
                          user.full_name :
                          <a onClick={() => this.handleView(user)}>{user.full_name}</a>,
                        user.email,
                        user.phone_num || <small><Trans i18nKey="organisation:users_email_empty" /></small>,
                      ]}
                      placeholder={(
                        <div className="Users__Placeholder">
                          <div>
                            <Trans i18nKey="organisation:users_filter_empty" />
                            <br />
                            <img src="/static/images/people.svg" alt="PeopleVector" />
                          </div>
                        </div>
                      )}
                      ActionComponent={({ item }) => (
                        <UserActions
                          item={item}
                          loggedUser={loggedUser}
                          onView={this.handleView}
                          onEdit={this.handleEdit}
                          onResendInvitation={
                            (user) => this.handleResendInvitation(
                              user, match.params.filter === EUserStatus.NOT_INVITED
                            )
                          }
                          onDelete={this.props.deleteUser}
                          userStatus={filter}
                        />
                      )}
                    />
                  </Route>
                </Switch>
              </Overview.Content>
            </Overview>
          </Container.Content>
        </Container>
      </>
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state) => ({
  loggedUser: userSelector.selected(state),
  organisation: organisationSelector.selected(state),
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
  roles: organisationSelector.roles(state),
  users: usersSelector(state),
  counts: state.organisation.users.counts,
});

const mapDispatchToProps = {
  fetchUsers: require('../../../organisation/actions').fetchUsers,
  fetchRoles: require('../../../organisation/actions').fetchRoles,
  resendInvitation: require('../../actions').resendInvitation,
  fetchCounts: require('../../actions').fetchUserCounts,
  deleteUser: require('../../actions').deleteUser,
  reinviteUsers: require('../../actions').reinviteUsers,
  destroyForm: require('redux-form').destroy,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(
  pageWrapper(EEventNames.VISITED_ORGANISATION_USERS_PAGE)(OrganisationUsers))
);
