import React from 'react';
import moment from 'moment';
import { connect, useDispatch, ConnectedProps } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { FailedRequest } from '@common/services/api';
import { usePageTracker } from '@common/hooks';
import { EEventNames } from '@common/services/analytics';
import { AsyncTable } from '@common/components/table';
import Confirm from '@common/components/confirm-button';
import { Button } from '@common/components/button';
import * as requestsSelector from '../../selectors/access-requests';
import * as adminActions from '../../actions';
import type { Network } from '@common/types/objects';
import type { AccessRequest } from '../../types';
import { AlertService } from '@common/services/alert';

type RowProps = {
  item: AccessRequest;
  refetchItems: () => Promise<unknown>;
};

const RequestActionsComponent = ({ item, refetchItems }: RowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleApprove = async (request: AccessRequest) => {
    try {
      await dispatch(adminActions.approveAccessRequest(request.id));

      AlertService.success(t('organisation:access_request_approved'));
    } catch (err) {
      const response = err as FailedRequest;

      // 409 means request has already been handled, refetch list to remove any other requests which have already been handled
      if (response.status_code === 409) refetchItems();

      AlertService.forStatus(response.status_code, {
        409: response.detail,
        warning: t('organisation:access_request_approve_warning'),
        error: t('organisation:access_request_approve_warning'),
      });
    }
  };

  const handleReject = async (request: AccessRequest) => {
    try {
      await dispatch(adminActions.rejectAccessRequest(request.id));

      AlertService.success(t('organisation:access_request_rejected'));
    } catch (err) {
      const response = err as FailedRequest;

      // 409 means request has already been handled, refetch list to remove any other requests which have already been handled
      if (response.status_code === 409) refetchItems();

      AlertService.forStatus(response.status_code, {
        409: response.detail,
        warning: t('organisation:access_request_reject_warning'),
        error: t('organisation:access_request_reject_warning'),
      });
    }
  };

  return (
    <>
      <Confirm
        title={t('organisation:access_request_approve_modal_title')}
        description={(
          <Trans
            i18nKey="organisation:access_request_approve_modal_description"
            values={{ email: item.email }}
            components={[<b />]}
          />
        )}
        confirmText={t('core:confirm')}
        onConfirm={() => handleApprove(item)}
      >
        <Button type="primary" size="small">{t('organisation:approve_request')}</Button>
      </Confirm>
      <Confirm
        title={t('organisation:access_request_reject_modal_title')}
        description={(
          <Trans
            i18nKey="organisation:access_request_reject_modal_description"
            values={{ email: item.email }}
            components={[<b />]}
          />
        )}
        confirmText={t('core:confirm')}
        onConfirm={() => handleReject(item)}
      >
        <Button type="inverted-primary" size="small">{t('organisation:reject_request')}</Button>
      </Confirm>
    </>
  );
};

// @ts-expect-error
const mapStateToProps = (state) => ({
  requests: requestsSelector.items(state),
});

const connector = connect(mapStateToProps, adminActions);

type Props = ConnectedProps<typeof connector> & {
  query: string | null;
  network?: Network;
};

export const AccessRequestListComponent = ({ requests, network, query, fetchAccessRequests }: Props) => {
  usePageTracker(network
    ? EEventNames.VIEWED_ACCESS_REQUESTS_FOR_NETWORK
    : EEventNames.VIEWED_ACCESS_REQUESTS_FOR_ORGANISATION);

  const { t } = useTranslation();

  const items = query
    ? requests.filter((request) => (
      `${request.first_name} ${request.last_name}`.toLowerCase().includes(query.toLowerCase())
      || request.email.toLowerCase().includes(query.toLocaleLowerCase())
    ))
    : requests;

  return (
    // @ts-expect-error
    <AsyncTable
      columns={[
        {
          label: t('organisation:users_columns_name'),
          className: 'Table__Cell__Title fs-exclude',
          size: 'large',
        }, {
          label: t('organisation:users_columns_email'),
          className: 'fs-exclude',
        }, {
          label: t('organisation:users_columns_network'),
          className: 'hidden-sd hidden-md fs-exclude',
        }, {
          label: t('organisation:users_columns_function'),
          className: 'hidden-sd hidden-md fs-exclude',
        }, {
          label: t('organisation:users_columns_requested_at'),
        },
      ]}
      items={items}
      data={{
        onFetch: fetchAccessRequests,
        filter: {
          networkId: network?.id,
        },
      }}
      renderRow={({ item }: RowProps) => [
        `${item.first_name} ${item.last_name}`,
        item.email,
        item.network?.name,
        item.function?.name,
        moment(item.created_at).calendar(),
      ]}
      placeholder={(
        <div className="Users__Placeholder">
          <div>
            {t(query ? 'organisation:users_no_requests_search' : 'organisation:users_no_requests')}
            <br />
            <img src="/static/images/people.svg" alt="PeopleVector" />
          </div>
        </div>
      )}
      ActionComponent={RequestActionsComponent}
    />
  );
};

export const AccessRequestList = connector(AccessRequestListComponent);
