import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { TableDropdown } from '../../../../common/components/table';
import ConfirmButton from '../../../../common/components/confirm-button';

class UserActions extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onResendInvitation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = { user: {} };

  constructor(props) {
    super(props);

    this.state = { invitation: { sending: false, sent: false } };

    this.handleEdit = () => props.onEdit(props.item);
    this.handleView = () => props.onView(props.item);
    this.handleDelete = () => props.onDelete(props.item.id);
    this.handleResendInvitation = this.handleResendInvitation.bind(this);
  }

  async handleResendInvitation() {
    this.setState({ invitation: { sending: true, sent: false } });

    await this.props.onResendInvitation(this.props.item);

    this.setState({ invitation: { sending: false, sent: true } });
  }

  render() {
    const { invitation } = this.state;
    const { item, loggedUser, t } = this.props;

    return (
      <TableDropdown id="user-actions-dropdown" disabled={invitation.sending}>
        <TableDropdown.Item onClick={this.handleEdit}>
          <Trans i18nKey="network:user_actions_edit_button" />
        </TableDropdown.Item>
        <TableDropdown.Item onClick={this.handleView}>
          <Trans i18nKey="network:user_actions_view_button" />
        </TableDropdown.Item>
        {!item.invited_at && !item.last_login && (
          <TableDropdown.Item onClick={this.handleResendInvitation}>
            <Trans i18nKey="network:user_actions_send_invitation" />
          </TableDropdown.Item>
        )}
        {item.invited_at && !item.last_login && (
          <TableDropdown.Item
            onClick={!invitation.sent ? this.handleResendInvitation : () => false}
            disabled={invitation.sent}
          >
            {invitation.sent ? t('network:user_actions_invitation_sent') : t('network:user_actions_resend_invitation')}
          </TableDropdown.Item>
        )}
        {!item.external_id && item.id !== loggedUser.id && (
          <ConfirmButton
            danger
            title={t('network:user_actions_delete_confirm_title')}
            description={t('network:user_actions_delete_confirm_descripton')}
            onConfirm={this.handleDelete}
            confirmText={t('network:user_actions_delete_confirm_button')}
          >
            <TableDropdown.Item danger><Trans i18nKey="network:user_actions_delete_button" /></TableDropdown.Item>
          </ConfirmButton>
        )}
      </TableDropdown>
    );
  }
}

export default withTranslation()(UserActions);
